import React from "react"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroSubpage from "../../../../components/heroes/hero-subpage"
import ClientsTicker from "../../../../components/misc/clients-ticker"
import DemoTrial from "../../../../components/ctas/demo-trial"

const SMSMailingUnsubscribePage = ({ intl, location }) => (
  <Layout>
    <Seo lang={intl.locale} title={intl.formatMessage({id : "pages.mailing.sms.unsubscribe.title"})} description={intl.formatMessage({id : "pages.mailing.sms.unsubscribe.description"})} pathname={location.pathname} />
    <HeroSubpage theme={`primary`} content={{title: intl.formatMessage({id : "pages.mailing.sms.unsubscribe.title"}), text: intl.formatMessage({id : "pages.mailing.sms.unsubscribe.description"})}} />
    <ClientsTicker content={{title: intl.formatMessage({ id: "pages.mailing.sms.unsubscribe.ticker.title" })}}/>
    <DemoTrial />
  </Layout>
)

export default injectIntl(SMSMailingUnsubscribePage)
